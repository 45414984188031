<template>
  <div class="ant-card" id="course_category">
      <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
          <a-tabs v-model="activeKey">
              <a-tab-pane :key="1" tab="产品销售和整体播放数据">
                  <salesPlay></salesPlay>
              </a-tab-pane>
          </a-tabs>
      </a-spin>
      <router-view/>
  </div>
</template>

<script>
import salesPlay from "./components/salesPlay"
export default {
  components: {salesPlay},
  data() {
      return {
          spinning: false, // 全局loading
          tableLoading: false,
          activeKey:1,
      };
  },
  
  methods: {
      // 回到顶部的函数
      targetFn() {
          return document.querySelector("#course");
      },
  }
};
</script>

<style lang="less" scoped>
#course_category {
  padding: 10px 20px;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
